.navbar {
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: row wrap;
  padding: 40px 0;
  z-index: 3;
  backdrop-filter: blur(10px);
}

.buttonBar {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.overlayContainer {
  position: sticky;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.navbarButton {
  background-color: rgb(153, 153, 153);
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
}
.buttonFront {
  display: block;
  padding: 12px 20px;
  border-radius: 12px;
  width: 160px;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: whitesmoke;
  color: rgb(255, 115, 0);
  /* color: rgba(119, 0, 255); */
  transform: translateY(-6px);
}

.navbarButton:hover .buttonFront {
  background-color: rgb(255, 115, 0);
  /* background-color: rgba(119, 0, 255); */
  color: whitesmoke;
  transition: .3s ease-in 0s all;
  }

.navbarButton:hover {
  background-color: rgb(190, 86, 0);
  /* background-color: rgb(70, 0, 150); */
  transition: .3s ease-in 0s all;
}

.navbarButton:active .buttonFront {
  transform: translateY(-2px);
  transition: 0s ease-out 0s all;
}

@media only screen and (max-width: 700px) {
  .buttonBar {
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    max-height: 140px;
  }

  .navbar {
    padding: 20px 0;
  }

  .navbarButton {
    /* transform: scale(0.9); */
    margin: 10px 0;
  }

}

@media only screen and (max-width: 400px) {
  .navbarButton,
  .buttonFront {
    width: 135px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 315px) {
  .navbarButton,
  .buttonFront {
    width: 110px;
    font-size: 0.9rem;
  }
}