.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: whitesmoke;
  font-size: 1em;
  margin-top: 10%;
}

.footer p {
  padding: 0 0 20px 20px;
}

@media only screen and (max-width: 400px) {
  .footer p {
    padding: 0 0 10px 10px;
  }
}