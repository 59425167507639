.bodyContainer {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
}

.header {
  padding-bottom: 40px;
  text-align: center;
  /* text-shadow:
  .5px .5px 0 #a7a7a7,
  -.5px .5px 0 #a7a7a7,
  -.5px -.5px 0 #a7a7a7,
  .5px -.5px 0 #a7a7a7,
  0px 4px 0px #a7a7a7; */
}

.header h1 {
  margin: 0;
  font-size: 8em;
  font-weight: 600;
}

.header h2 {
  margin: 0;
  font-size: 4em;
  font-weight: 600;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  filter: grayscale(100%);
}

.body h4 {
  font-size: 2em;
  font-weight: 600;
  margin: .5em 0;
}

.bodyMobile {
  display: none;
  filter: grayscale(100%);
}


@media only screen and (max-width: 1400px){
  .body h4 {
    font-size: 1.5em;
    text-shadow:
    0px 2px 0px #a7a7a7;
  }
}

@media only screen and (max-width: 1100px){
  .bodyContainer {
    height: 60vh;
  }
  
  .header h1 {
    font-size: 6em;
  }
  .header h2 {
    font-size: 3em;
  }
  .body h4 {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 900px){
  .body h4 {
    font-size: 1em;
  }
}

@media only screen and (max-width: 750px){
  .body h4 {
    font-size: .75em;
  }

  .header h1 {
    font-size: 4em;
  }

  .header h2 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 600px){
  .body {
    display: none;
  }
  

  .bodyMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .bodyMobile h4 {
    font-size: .8em;
    font-weight: 600;
    text-shadow:
    .15px .15px 0 #a7a7a7,
    -.15px .15px 0 #a7a7a7,
    -.15px -.15px 0 #a7a7a7,
    .15px -.15px 0 #a7a7a7,
    0px .5px 0px #a7a7a7;
    margin: .5em 0;
  }
  
}

@media only screen and (max-width: 360px){
  .bodyContainer {
    height: 70vh;
  }
  .header h2 {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 250px){
  .bodyMobile h4 {
    font-size: .7em;
  }
}