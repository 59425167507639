.projectsOverlay {
  padding: 30px 0 0 0;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

/* Button Container */

.buttonsList {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
}

.buttonBox{
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.buttonBox h3 {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-shadow: 0 1.5px 0px rgb(134, 134, 134);
  width: 80px;
}

.projectButton {
  background-color: rgb(153, 153, 153);
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.buttonFront {
  display: block;
  padding: 0;
  border-radius: 12px;
  height: 80px;
  width: 80px;
  background-color: whitesmoke;
  transform: translateY(-6px);
  filter:grayscale(100%);
}

.buttonBox:hover {
  transform: scale(1.125);
  transition: .3s ease-in 0s all;
}

.projectButton:hover .buttonFront {
  filter: grayscale(0%);
  transition: .3s ease-in 0s all;
}

.projectButton:active .buttonFront {
  transform: translateY(-2px);
  transition: 0s ease-out 0s all;
}

/* Project Container */

.projectTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  padding: 0;
  margin: 0;
}

.projectText {
  display: flex;
  flex-flow: row wrap;
  padding: 15px;
  border-radius: 12px;
  height: fit-content;
  max-width: 70%;
  background-color: rgba(245, 245, 245);
  color: rgb(65, 63, 70);
  box-shadow: 
  1px 1px 0 rgb(8, 8, 8),
  -1px 1px 0 rgb(201, 201, 201),
  -1px -1px 0 rgb(201, 201, 201),
  1px -1px 0 rgb(201, 201, 201),
  0px 6px 0px rgb(153, 153, 153);
}

.projectText p {
  padding: 0;
  margin: 0 0 0 0.5em;
  font-weight: 600;
  font-size: 1.5em;
}

.projectText h4 {
  width: 100%;
  font-size: 1.5em;
  font-weight: 600;
  padding: 0;
  margin: 0 0 0.5em 0;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.projectDescription {
  padding: 0;
  margin: 0;
  text-align: left;
  width: 50%;
  height: 100%;
}

.featuresList {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 50%;
  height: 100%;
}

.links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  padding: 15px 0 0 0;
  width: 100%;
}

.links a {
  text-decoration: none;
}

.links button {
  background-color: whitesmoke;
  border-radius: 6px;
  border: none;
  margin: 0 10px;
  padding: 10px 10px;
  cursor: pointer;
  box-shadow: 
  .5px .5px 0 rgb(201, 201, 201),
  -.5px .5px 0 rgb(201, 201, 201),
  -.5px -.5px 0 rgb(201, 201, 201),
  .5px -.5px 0 rgb(201, 201, 201),
  0px 6px 0px rgb(153, 153, 153);
}

.links button:hover {
  background-color: rgb(255, 115, 0);
  transition: .3s ease-in 0s all;
}

.links button:hover {
  box-shadow:
  .5px .5px 0 rgb(235, 106, 0),
  -.5px .5px 0 rgb(235, 106, 0),
  -.5px -.5px 0 rgb(235, 106, 0),
  .5px -.5px 0 rgb(235, 106, 0),
  0px 6px 0px rgb(190, 86, 0);
}

.links button:hover p {
  color: whitesmoke;
  transition: .3s ease-in 0s all;
}

.links button:active {
  transform: translateY(2px);
  transition: 0s ease-out 0s all;
}

.links p {
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(255, 115, 0);
}

.disclaimer {
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 35px 0 0 0;
  width: 100%;
  font-size: .6em;
}


@media only screen and (max-width: 1100px) {  
  .projectText {
    width: 100%;
  }
  
  .projectDescription {
    width: 100%;
    margin-bottom: 5px;
  }  
  
  .featuresList {
    width: 100%;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 650px) {
  .buttonsList {
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 400px) {
  .buttonBox {
    padding: 10px;
  }

  .buttonBox h3 {
    font-size: 1em;
  }

  .projectText p {
    font-size: 1em;
  }

  .projectText h4 {
    font-size: 1em;
  }

}