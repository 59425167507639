* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: conic-gradient(at 0% 20%, rgba(0, 0, 0, 50%) 20deg, rgba(243, 243, 243, 0.5) 110deg, rgba(0, 0, 0, 50%) 160deg),
    conic-gradient(at 100% 20%, rgba(0, 0, 0, 0.9) 200deg, rgba(255, 255, 255, 0.9) 250deg, rgba(0, 0, 0, 0.9) 340deg); */

  /* background:  conic-gradient(at 0% 10%, rgba(0, 0, 0, 50%) 90deg, rgba(119, 0, 255, 0.5) 110deg, rgba(0, 0, 0, 50%) 130deg),
    conic-gradient(at 100% 10%, rgba(0, 0, 0, 0.9) 230deg, rgba(119, 0, 255, 0.9) 250deg, rgba(0, 0, 0, 0.9) 270deg); */

  /* background: conic-gradient(at 50% 0%, rgba(0, 0, 0) 90deg, rgba(119, 0, 255, 0.788) 180deg, rgba(0, 0, 0) 270deg); */

  background-color: rgb(65, 63, 70);


  background-repeat: no-repeat;
  color: whitesmoke;
  background-size: cover;
  
}

@keyframes fade-in {
  0% {
    filter:brightness(0);
  }
  100% {
    filter: brightness(1);
  }
}

body {
  animation: fade-in 1.5s ease-in;
}