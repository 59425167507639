.iconBar {
  position: fixed;
  top: 250px;
  display: flex;
  right: 0;
  z-index: 4;
  height: 448px;
  padding: 0 30px;
}

.iconBar ul {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.iconBar li {
  margin: 0;
}

.iconButton {
  background-color: rgb(153, 153, 153);
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.buttonFront {
  display: block;
  padding: 0;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  background-color: whitesmoke;
  transform: translateY(-6px);
  filter: grayscale(100%);
}


.iconBar li:hover {
  transform: scale(1.125);
  transition: .3s ease-out 0s all;
}
.iconButton:hover .buttonFront {
  filter: grayscale(0%);
  transition: .3s ease-out 0s all;
}

.iconButton:active .buttonFront {
  transform: translateY(-2px);
  transition: 0s ease-out 0s all;
}

@media only screen and (max-width: 1200px) {
  .iconBar {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
    height: 100%;
    padding: 30px 0;
  }
  .iconBar ul {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .iconButton {
    transform: scale(1.125);
  }
}

@media only screen and (max-width: 400px) {
  .iconButton {
    transform: scale(1);
  }
}