.skillsetOverlay {
  padding: 30px 0 0 0;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0;
}

.buttonsList {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-flow: row nowrap;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
}

.buttonBox{
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0; 
}

.buttonBox h3 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 0 1.5px 0px rgb(134, 134, 134)
}

.skillsetButton {
  background-color: rgb(153, 153, 153);
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer; 
  outline-offset: 4px;
}

.buttonFront {
  display: block;
  padding: 0;
  border-radius: 12px;
  width: 80px;
  height: 80px;
  background-color: whitesmoke;
  transform: translateY(-6px);
  filter:grayscale(100%);
}

.buttonBox:hover {
  transform: scale(1.125);
  transition: .3s ease-in 0s all;
}

.skillsetButton:hover .buttonFront {
  filter: grayscale(0%);
  transition: .3s ease-out 0s all;
}


.skillsetButton:active .buttonFront {
  transform: translateY(-2px);
  transition: 0s ease-out 0s all;
}

/* Skill Text Container */

.skillTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  padding: 0;
  margin: 0;
}

.skillText {
  display: flex;
  flex-flow: row wrap;
  padding: 10px 10px;
  border-radius: 12px;
  height: fit-content;
  max-width: 70%;
  background-color: rgba(245, 245, 245);
  color: rgb(255, 115, 0);
  box-shadow: 
  1px 1px 0 rgb(201, 201, 201),
  -1px 1px 0 rgb(201, 201, 201),
  -1px -1px 0 rgb(201, 201, 201),
  1px -1px 0 rgb(201, 201, 201),
  0px 6px 0px rgb(153, 153, 153);
}

.projectList {
  display: flex;
  flex-flow: column wrap;
  max-height: 80%;
  text-align: left;
  list-style: none;
  padding: 0;
}

.buttonBox h3 {
  font-size: 1.25em;
}

.projectList p {
  font-size: 1.5em;
  padding: 0;
  margin: 0 0 0 0.5em;
  font-weight: 600;
}

.skillText h4 {
  font-size: 1.5em;
  font-weight: 600;
  width: 100%;
  padding: 0;
  margin: 0 0 0.5em 0;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
} 

@media only screen and (max-width: 600px) {  
  .buttonsList {
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  }

  /* .buttonFront {
    width: 48px;
    height: 48px;
  } */

  /* .skillsetButton:hover .buttonFront {
    width: 55px;
    height: 55px;
    transition: .3s ease-in 0s all;
  } */
}


@media only screen and (max-width: 400px) {
  .buttonBox {
    padding: 10px;
  }

  .buttonBox h3 {
    font-size: 0.8em;
  }

  .skillText h4 {
    font-size: 1.25em;
  }

  .skillText p {
    font-size: 1.25em;
  }
}