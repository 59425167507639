.about {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0 0 0;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.profileImg {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  box-shadow: 
  2px 2px 0 rgb(201, 201, 201),
  -2px 2px 0 rgb(201, 201, 201),
  -2px -2px 0 rgb(201, 201, 201),
  2px -2px 0 rgb(201, 201, 201),
  0px 6px 0px rgb(153, 153, 153);
}

.personalIntro {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 20px;
  padding: 20px 20px;
  border-radius: 15px;
  height: fit-content;
  width: 70%;
  background-color: rgba(245, 245, 245);
  box-shadow: 
  1px 1px 0 rgb(201, 201, 201),
  -1px 1px 0 rgb(201, 201, 201),
  -1px -1px 0 rgb(201, 201, 201),
  1px -1px 0 rgb(201, 201, 201),
  0px 6px 0px rgb(153, 153, 153);
}

.personalIntro h4 {
  width: 100%;
  text-align: left;
  color: rgb(255, 115, 0);
  font-weight: 600;
  font-size: 1.5em;
  margin: 0 0 .5em 0;
}

.personalIntro p {
  color: rgb(255, 115, 0);
  font-weight: 400;
  font-size: 1.5em;
  margin: 0;
}

@media only screen and (max-width:1100px) {
  .profileImg {
    height: 120px;
    width: 120px;
  }
}

@media only screen and (max-width: 700px) {
  .profileImg {
    height: 110px;
    width: 110px;
  }
}

@media only screen and (max-width: 600px) {
  .about {
    flex-flow: row wrap;
  }

  .personalIntro {
    width: 100%;
    margin: 0 20px;
  }

  .profileImg {
    height: 125px;
    width: 125px;
  }

}